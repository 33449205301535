<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      :max-width="dialogWidth"
      scrollable
    >
      <v-card v-if="item">
        <v-toolbar
        >
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ item.nombre }}
          </v-toolbar-title>
        </v-toolbar>
        <v-list
          two-line
          subheader
        >
          <v-subheader>{{ $t('app.general.information') }}</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.cancha.nombre }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ $t('app.canchas.single') }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.fecha }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ $t('app.headers.date') }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.hora }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ $t('app.headers.time') }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.descripcion }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ $t('app.headers.description') }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.contacto }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ $t('app.headers.contact') }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.telefonos.join(', ') }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ $t('app.headers.phone') }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name: 'JuegosInformalesDetail',
    data () {
      return {
        dialog: false,
        item: false,
      }
    },
    mounted() {
      EventBus.$on('juegos-informales-detail', (item) => {
        this.item = item
        this.dialog = true
      })
    },
  }
</script>

<style scoped>

</style>
